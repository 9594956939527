<template>
  <div class="intelligent-detection">
    <!-- 产品质量智能检测 -->
    <div class="content">
      <h1 id="znjc">产品质量智能检测</h1>

      <div class="second-nav">
        <ul class="second-level-directory" id="second-level-directory">
          <div class="nav-box">
            <li class="nav-link" v-for="nav,index in nav_data" :key="index">
              <a :href="nav.href">{{ nav.name }}</a>
            </li>
          </div>
        </ul>
      </div>
      <div style="height: 30px"></div>

      <div class="industry-introduction" id="introduction" style="margin-top: 50px;">
        <h1 style="text-align: center;">系统介绍</h1>
        <div style="height: 40px"></div>
        <p>基于机器视觉技术及深度学习技术，为中小型注塑制品质量提供完整的检测方案，实现注塑制品质量检测的自动化与智能化。</p>
      </div>
    </div>

    <div class="core-function" id="corefunction">
      <h1>核心功能</h1>
      <div class="fun-content">
        <ul class="nav">
          <li
            v-for="(nav, index) in nav_info"
            :key="index"
            @click="handleNav(index)"
            :class="{ active: index == activeIndex }"
          >
            {{ nav.title }}
          </li>
        </ul>
        <div style="height: 40px"></div>
        <div class="img">
          <div v-for="(item, index) in nav_info" :key="index" class="myBox">
            <div class="myImg">
              <img :src="item.img_url" alt="" width="1200px">
            </div>
            <div class="desc"><span>{{item.desc}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav_info: [
        { title: '印刷字体检测', desc: '偏位、摸花、漏底、重影等多缺陷同时检测；缺陷检测精度达99.9%；单个缺陷检测时间在0.2秒以内，最快可达2.5ms。', img_url: require('@/assets/images/product/intelligentDetection/fun1.png') },
        { title: '微型透明件外观缺陷检测', desc: '对缺胶、黑点、气泡、异物缺陷检测准确度在99%以上；单张图像检测时间在0.2秒以内。', img_url: require('@/assets/images/product/intelligentDetection/fun2.png') },
        { title: '精密外观件缺陷检测', desc: '零件尺寸为35mm×6mm，凹坑面积不超过 0.125mm2 ，毛刺不超过0.08mm，检测准确度可达99%；单张图像检测时间在0.2秒以内，最快可达2.5ms。', img_url: require('@/assets/images/product/intelligentDetection/fun3.png') },
        { title: '医用零件检测', desc: '目前图像样品准确率底部缺胶100%、头部缺胶94%、冷胶92%、黑点100%；单张图像检测时间在0.2秒以内，最快可达2.5ms。', img_url: require('@/assets/images/product/intelligentDetection/fun4.png') },
        { title: '微型零件尺寸检测', desc: '尺寸测量精度达1微米；实现生产线完全自动化，单张图像检测时间在0.2秒以内，最快可达2.5ms。', img_url: require('@/assets/images/product/intelligentDetection/fun5.png') },
      ],
      activeIndex: 0,
      nav_data: [
        { name: "系统介绍", href: '#introduction' },
        { name: "核心功能", href: '#corefunction' },
      ]
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleWheel)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleWheel);
  },
  methods: {
    handleNav(index) {
      this.activeIndex = index;
      var box = document.querySelectorAll(".myBox");
      for (let i = 0; i < box.length; i++) {
        box[i].style.transform = `translateX(${index * -1200}px)`;
        box[i].style.transition = "transform 0.5s ease-in-out";
      }
    },
    handleWheel() {
      var targetUrl = document.getElementById('second-level-directory')
      var znjc = document.getElementById('znjc')
      var introduction = document.getElementById('introduction')
      var distanceToTop = znjc.getBoundingClientRect().top;
      const topOffset = introduction.getBoundingClientRect().top;

      if (distanceToTop < 141) {
        targetUrl.style.position = 'fixed'
        targetUrl.style.top = '90px'
      } else if (distanceToTop == 141) {
        targetUrl.style.top = '200px'
      }

      const updateLinkStyles = function(index) {
        const lis = document.querySelectorAll(".nav-link")
        lis.forEach((li, i) => {
          if (i === index) {
            li.style.borderBottom = '2px solid red';
          } else {
            li.style.borderBottom = 'none';
          }
        })
      }
      if (topOffset <= 305 && topOffset > 43) {
        updateLinkStyles(0);
      } else if (topOffset <= 43) {
        updateLinkStyles(1);
      }
    },
  }
};
</script>

<style lang="less" scoped>
.fun-content {
  .nav {
    margin: 0 auto;
    width: 1100px;
    display: flex;
    justify-content: space-around;
    li {
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .img {
    width: 1200px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    margin-left: 50%;
    transform: translate(-50%);
    border-radius: 35px;
    background: white;
    box-sizing: border-box;
    .myImg {
      display: flex;
    }
    .desc {
      width: 80%;
      margin: 70px auto;
      font-size: 20px;
      letter-spacing: 3px;
      color: #828181;
    }
  }
}
#introduction, #corefunction {
  scroll-margin-top: 200px;
}
</style>